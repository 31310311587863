<template lang="pug">
.plyr(:class="{ watched, playing }")
  // Generic Vimeo or Youtube embedding
  .player(
    v-if="!videoObj.player_embed_url && (type === 'vimeo' || type === 'youtube')",
    :id="'player' + id",
    :data-plyr-provider="type",
    :data-plyr-embed-id="id"
  )
  //.plyr__video-embed(v-if="videoObj.player_embed_url" :id="'player' + id" v-html="videoObj.embed.html")
  
  // New HLS video format for subsplash videos
  .vls-player(v-if="!videoObj.player_embed_url && type === 'hls'" @click="loadHLS")
    button.btn.btn-outline-secondary(v-show="!hlsLoaded" style="width:100%")
      span.material-icons subscriptions
      | Play
    video(preload="none" :id="'player' + id" controls crossorigin ref='hlsPlayer' v-show="hlsLoaded")
      source(
        :src="id"
      )

  // Vimeo API retrieval sources
  video(
    v-if="videoObj.player_embed_url",
    :id="'player' + id",
    playsinline,
    controls,
    :data-poster="videoObj.pictures.sizes[videoObj.pictures.sizes.length - 1].link",
    preload="none"
  )
    source(
      v-for="file in videoObj.files",
      :src="file.link",
      :type="file.type",
      :size="file.height"
    )

  // Progress bar
  .progress(v-if="lastTimeWatched", style="width: 100%; height: 3px")
    .progress-bar(
      :style="{ width: Math.floor((time / duration) * 100) + '%' }"
    )
  span.extra-info(v-if="lastTimeWatched") Watched {{ fromNow }}, {{ Math.floor((duration - time) / 60) }} minutes left
</template>

<script>
import Plyr from "plyr";
import "plyr/dist/plyr.css";
import API from "./../API.js";

export default {
  props: ["type", "id", "user", "postId", "profileId"],
  data() {
    return {
      player: {},
      time: 0,
      watched: false,
      lastTimeWatched: 0,
      duration: 0,
      playing: false,
      videoObj: {},
      hlsLoaded: false,
      hasSetOnPlace: false,
    };
  },
  computed: {
    fromNow() {
      if (this.lastTimeWatched === 0) return "";
      return window.moment(this.lastTimeWatched).fromNow();
    },
  },
  methods: {
    loadHLS(){
      if (this.type === "hls" && !this.hlsLoaded) {
        try {
          if (window.Hls.isSupported()) {
            var hls = new window.Hls();
            hls.loadSource(this.id);
            hls.attachMedia(this.$refs.hlsPlayer);
          }
          this.hlsLoaded = true;
        } catch (e) {
          console.error(e);
        }
      }
      //this.loadPlayer();
    },
    loadPlayer() {
      if (this.type === "hls" && !this.hlsLoaded) {
       //return 0;
      }
      this.player = new Plyr(this.$refs.hlsPlayer, {
        hideControls: false,
        fullscreen: { enabled: true, fallback: true, iosNative: true },
        settings: ["captions", "quality", "speed", "loop"],
        controls: [
          "play-large",
          "play",
          "progress",
          "current-time",
          "mute",
          "volume",
          "captions",
          "settings",
          "pip",
          "airplay",
          "fullscreen",
        ],
      });
      if (this.type === "youtube") return 0;
      this.player.toggleControls(false);
      let ls = this.user.data[this.postId];
      if (ls) {
        //console.log("Progress Data:", ls);
        this.time = ls.time || 0;
        this.watched = ls.watched || false;
        this.lastTimeWatched = ls.ts || 0;
        this.duration = ls.duration || 0;
      }
      setTimeout(() => {
        if (this.time) this.player.currentTime = this.time;
      }, 5000);
      this.player.on("playing", () => {
        //console.log("Play event", this.id);
        this.player.toggleControls(true);
        this.duration = this.player.duration;
        setTimeout(() => {
          this.playing = this.player.playing;
        }, 1000);
      });
      this.player.on("canplay", () => {
        //console.log("canplay", this.id);
        if (this.time && !this.hasSetOnPlace) {
          this.player.currentTime = this.time;
          this.hasSetOnPlace = true;
        }
        if(this.hasSetOnPlace && !this.player.playing)
          this.player.play();
      });
      this.player.on("progress", (event) => {
        //console.log("progress")
        if (!this.player.playing) return 0;
        if (this.player.playing != this.playing) {
          this.player.currentTime = this.time;
          this.playing = this.player.playing;
        }
        const instance = event.detail.plyr;
        this.time = instance.currentTime;
        const ts = new Date();
        this.lastTimeWatched = ts.getTime();
        API.setDataValue(this.postId, {
          watched: this.watched,
          time: this.time,
          ts: ts.getTime(),
          duration: this.player.duration,
          postId: this.postId,
          profileId: this.profileId,
        });
        setTimeout(() => {
          this.playing = this.player.playing;
        }, 1000);
      });

      this.player.on("pause", (event) => {
        //console.log("pause")
        const instance = event.detail.plyr;
        this.time = instance.currentTime;
        const ts = new Date();
        this.lastTimeWatched = ts.getTime();
        API.setDataValue(this.postId, {
          watched: this.watched,
          time: this.time,
          ts: ts.getTime(),
          duration: this.player.duration,
          postId: this.postId,
          profileId: this.profileId,
        });
        setTimeout(() => {
          this.playing = this.player.playing;
        }, 1000);
      });

      this.player.on("ended", (event) => {
        //console.log('ended')
        const instance = event.detail.plyr;
        this.time = instance.currentTime;
        this.watched = true;
        const ts = new Date();
        this.lastTimeWatched = ts.getTime();
        API.setDataValue(this.postId, {
          watched: this.watched,
          time: this.time,
          ts: ts.getTime(),
          duration: this.player.duration,
          postId: this.postId,
          profileId: this.profileId,
        });
        setTimeout(() => {
          this.playing = this.player.playing;
        }, 1000);
      });
    },
  },
  mounted() {
    if (this.type !== "vimeo") {
      return this.loadPlayer();
    }
    API.getVideo(this.id).then((videoObj) => {
      this.videoObj = videoObj;
      setTimeout(() => {
        this.loadPlayer();
      }, 300);
    });
  },
};
</script>

<style lang="sass">
.plyr--video
  width: 100%

.progress, .extra-info
  opacity: 1
  transform: scale(1)
  overflow: hidden
  max-height: 500px
  transition: opacity 1s, max-height 1s
  position: absolute
  bottom: -27px

.playing
  .progress, .extra-info
    opacity: 0
    max-height: 0
        //transform: scale(0)
</style>