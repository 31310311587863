<template lang="pug">
.media-content
    .video-content(v-if="videoId && videoId[0]" style="padding-bottom:10px")
        Plyr(:type="videoId[0]", :id="videoId[1]" :user="user" :profileId="post ? post.profileid : ''" :postId="post ? post._id : ''")
    .images(v-if="imagesTag && imagesTag.length < 3")
        .img-wrapper(v-for="(img, i) in imagesTag")
            img.img-fluid(v-if="i < 2 || showAllImages" :src="img[1]" :class="{multiple: imagesTag.length > 1}" loading="lazy")
        .see-more(v-if="!showAllImages && imagesTag.length > 2")
            p.fw-bold.pointer(@click="showAllImages = true") ... see more
    .images(v-if="imagesTag && imagesTag.length > 2")
        HorizontalProfilesCarousels(@scroll.native="imgOnHover")
            .img-wrapper-horizontal(v-for="(img, i) in imagesTag")
                img(v-if="i < hIndex" :src="img[1]")
    .iframe(v-if="iframeTag")
        iframe.iframe-media(:src="iframeTag[1]" :class="{iframe_short: iframeTag[1].includes('drive.google')}" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true")
</template>

<script>
import Plyr from "./Plyr.vue";
import HorizontalProfilesCarousels from './HorizontalProfilesCarousels.vue';
export default {
    props: ["content", "user", "post"],
    data(){
        return {
            showAllImages: false,
            hIndex: 4,
        }
    },
    components:{Plyr, HorizontalProfilesCarousels},
    computed:{
        videoId(){
            let vimeoTag = this.content.match(/@vimeo:[0-9]+/);
            let youtubeTag = this.content.match(/@youtube:[0-z]+/);
            let hlsTag = this.content.match(/@hls:.+\w/);
            if(!vimeoTag && !youtubeTag && !hlsTag) return '';
            let tag = youtubeTag || vimeoTag || hlsTag;
            tag = tag[0].substring(1);
            tag = tag.split(':');
            if(tag[0] === 'hls') {
                tag[1] = tag[1] + ":" + tag[2];
            }
            return tag;
        },
        imagesTag(){
            let images = this.content.match(/@image:[0-z|/|.|]+/g);
            if(!images) return 0;
            let Tags = [];
            images.forEach(i => {
                let tag = i.substring(1);
                let parts = [tag.substring(1, tag.indexOf(":")), tag.substring(tag.indexOf(":")+1)];
                if(parts[1].substring(0,4) != "http") parts[1] = "https://social.emmint.com/" + parts[1] + '?width=1000&height=1000';
                Tags.push(parts);
            });
            return Tags;
        },
        iframeTag(){
            let iframeMatch = this.content.match(/@iframe:.+\w/g);
            if(!iframeMatch) return 0;
            let tag = iframeMatch[0].substring(1);
            let parts = [tag.substring(1, tag.indexOf(":")), tag.substring(tag.indexOf(":")+1)];
            return parts;
        },
    },
    methods:{
        imgOnHover(event){
            let container = event.srcElement;
            let percentage = (container.scrollLeft + container.clientWidth) / container.scrollWidth;
            if(percentage>0.75 && this.hIndex < this.imagesTag.length){
                this.hIndex += 4;
                console.log("Loading more pictures", this.hIndex );
            }
        }
    }
}
</script>

<style lang="sass" scoped>
.img-wrapper
    display: inline
.img-wrapper-horizontal
    font-size: 100%
    display: table-cell
    padding-right: 10px
.images img
    max-height: 400px
    text-align: center
    display: block
    margin: 5px auto 0
    border-radius: 16px
    &.multiple
        display: inline
        max-width: 50%
        padding: 1px
        width: 50%
        max-height: 200px
        object-fit: cover
        margin: 0
        height: 200px
.iframe-media
    width: 100%
    min-height: 400px
.iframe_short
    min-height: 65px
    height: 65px
</style>