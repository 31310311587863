<template lang="pug">
.comment.card
    .card-body
        .person
            p
                UserName(:id="comment.profileid" style="font-weight: bold" :showImgCircle="true")
                span.small.created-at(style="padding-left:5px") {{fromNow}}
        .content
            p.card-text {{cleanContent}}
        Media(:content="comment.content")
        .reaction
            button.btn(@click="react") 
                span.material-icons.md-18 {{Object.keys(comment.reactions).length ? 'favorite' : 'favorite_border'}}
                span.muted.number-reactions {{Object.keys(comment.reactions).length}}
</template>

<script>
import Media from "./Media.vue";
import UserName from "./UserName.vue";
import Plyr from "./Plyr.vue";
export default {
    name: "Comment",
    props: ["comment", "index", "postid"],
    data(){
        return {}
    },
    components:{
        UserName,
        Plyr,
        Media
    },
    computed:{
        fromNow(){
            return window.moment(this.comment.createdAt).fromNow();
        },
        videoId(){
            let vimeoTag = this.comment.content.match(/@vimeo:[0-9]+/);
            let youtubeTag = this.comment.content.match(/@youtube:[0-z]+/);
            if(!vimeoTag && !youtubeTag) return '';
            let tag = youtubeTag || vimeoTag;
            tag = tag[0].substring(1);
            return tag.split(':');
        },
        cleanContent(){
            return this.comment.content.replace(/@[A-z]+:[0-z|/|.|&|]+/g, '')
        },
        ILikeThis(){
            return this.comment.reactions[this.$attrs.user._id] ? true : false;
        }
    },
    methods:{
        react(){
            this.$emit("newCommentReaction", this.postid, this.comment.createdAt, this.index)
        }
    }
}
</script>

<style lang="sass" scoped>
.comment.card
    .reaction
        position: absolute
        top: 10px
        right: 20px
    .person
        p
            margin-bottom: 0
</style>